@import '../../components/variables.scss';

.blog__box {
    margin-bottom: 10pc;
    text-align: center;

    .blog__header {
        display: flex;
        flex-direction: column;
        margin-top: 50px;
        background: #EBEFFA;
        justify-content: center;
        align-items: center;



        .item__grid {
            .title__txt {
                font-weight: 700;
                margin-bottom: 30px;
                margin-top: 6pc;
            }

            .body__txt {
                font-weight: 400;
                font-size: 22px;
                opacity: 0.8;
                margin-bottom: 7pc;
                text-align: justify;

                @include respond-to(small) {
                    text-align: center;
                }

                @include respond-to(medium) {
                    text-align: center;
                }

                @include respond-to(large) {
                    text-align: center;
                }
            }
        }

    }

    .cards__container {
        margin-top: 5pc;
        justify-content: center;

        .recent__grid {
            justify-content: center;
            .title__grid {
                margin-bottom: 30px;
            }

            .card__grid {
                justify-content: center;
                .card {
                    box-shadow: none;
                    margin-right: 2pc;
                    margin-left: 2pc;
                    margin-bottom: 3pc;
                    padding: 15px;

                    .cardMedia {
                        border-radius: 10px;
                    }

                    .card__content {
                        padding: 0;
                        margin-top: 25px;
                    }

                    .info__txt {
                        text-align: left;
                        color: #28d79c;
                        font-weight: 600;
                        font-size: 13px;
                    }

                    .card__title {
                        text-align: left;
                        font-weight: 600;
                        font-size: 20px;
                    }

                    .card__description {
                        text-align: left;
                    }

                    .read__more__btn {
                        text-transform: inherit;
                        font-weight: 600;
                        color: #28d79c;
                    }

                }
            }
        }

        .all__blogs__grid {
            // justify-content: flex-start;
            justify-content: center;
            margin-top: 5pc;
            .title__grid {
                margin-bottom: 30px;
            }

            .card__grid {
                justify-content: center;
                .card {
                    box-shadow: none;
                    margin-right: 2pc;
                    margin-left: 2pc;
                    margin-bottom: 3pc;
                    padding: 15px;

                    .cardMedia {
                        border-radius: 10px;
                    }

                    .card__content {
                        padding: 0;
                        margin-top: 25px;
                    }

                    .info__txt {
                        text-align: left;
                        color: #28d79c;
                        font-weight: 600;
                        font-size: 13px;
                    }

                    .card__title {
                        font-weight: 600;
                        font-size: 20px;
                        text-align: left;
                    }

                    .card__description {
                        text-align: left;
                    }

                    .read__more__btn {
                        text-transform: inherit;
                        font-weight: 600;
                        color: #28d79c;
                    }

                }
            }

            .css-to0tlk-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
                background-color: #FFF2F8;
                color: #28d79c;
            }
        }


    }
}