@import '../../components/variables.scss';

.faq__box {
    text-align: center;

    .container__grid {
        display: flex;
        flex-direction: column;
        margin-top: 50px;
        background: #EBEFFA;
        justify-content: center;
        align-items: center;

        .item__grid {
            .title__txt {
                font-weight: 700;
                margin-bottom: 30px;
                margin-top: 6pc;
            }

            .body__txt {
                font-weight: 400;
                font-size: 22px;
                opacity: 0.8;
                margin-bottom: 7pc;
                text-align: justify;

                @include respond-to(small) {
                    text-align: center;
                }

                @include respond-to(medium) {
                    text-align: center;
                }

                @include respond-to(large) {
                    text-align: center;
                }
            }
        }

    }

    .questions__container {
        margin-top: 5pc;
        justify-content: center;
        margin-bottom: 5pc;

        .item__grid {
            border: 1px solid rgba(7, 30, 48, 0.1);
            border-radius: 18px;
            padding: 25px;
            margin-bottom: 2pc;
            text-align: left;

            .question__txt {
                color: #11142D;
                font-weight: 600;
                font-size: 20px;
            }

            .answer__txt {
                font-weight: 400;
                font-size: 16px;
                color: #0D2637;
                opacity: 0.7;
            }
        }
    }
}