@import '../../components/variables.scss';

.issa__box {
    .container__grid {
        margin-top: 50px;
        background: #EBEFFA;
        justify-content: center;
        align-items: center;
        padding-top: 7pc;
        padding-bottom: 4pc;

        @include respond-to(small) {}

        @include respond-to(medium) {}

        @include respond-to(large) {}

        .item__grid {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding-left: 3pc;
            padding-right: 3pc;

            @include respond-to(small) {
                text-align: start;
                align-items: flex-start;
                padding-left: 0;
                padding-right: 0;
            }

            .title__txt {
                font-weight: 700;
                margin-bottom: 30px;
                font-family: $font-family
            }

            .body__txt {
                font-weight: 400;
                font-size: 22px;
                opacity: 0.8;
                margin-bottom: 3pc;
            }

            .member__btn {
                border-radius: 30px;
                padding: 10px 25px 10px 25px;
                text-transform: inherit;
                background: $primary;
                color: $dark;
                font-weight: 600;
                font-size: 18px;
            }

        }

        .img__grid {
            margin-top: 5pc;
            display: flex;
            justify-content: center;

            @include respond-to(small) {
                margin-top: 0;
            }

            img {
                width: 270px;
            }
        }

    }

    .info__box {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: #fff;
        padding-top: 6pc;
        text-align: center;
        padding-bottom: 4pc;

        .card__header__grid {
            justify-content: center;
        }

        .title {
            font-size: 48px;
            margin-bottom: 2rem;
            font-weight: 900;
            margin-bottom: 10px;
            text-align: center;
        }

        .paragraph {
            margin-bottom: 2rem;
            text-align: center;
            margin: 0 !important;
            font-weight: 300;
            font-size: 24px;
            color: #0D2637;
            opacity: 0.7;
        }

        .cardContainer {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 3pc;
            width: 100%;
            max-width: 100%;
        }

        .card {
            margin-bottom: 2rem;
            border-radius: 5px;
            border: 1px solid #E9E9E9;
            box-shadow: none;
            border-bottom-right-radius: 50px;
            margin: 35px 20px 10px 20px;
            max-width: 350px;


            .cardContent {
                display: flex;
                flex-direction: column;
                align-items: flex-start;

                .cardText {
                    font-size: 18px;
                    padding-left: 9px;
                    font-weight: 700;
                }

                .cardIcon {
                    padding: 10px;
                    margin-left: 10px;
                    margin-bottom: 20px;
                    margin-top: 10px;
                    border-bottom-right-radius: 15px;
                    .icon {
                        font-size: 34px;
                        color: $primary;
                    }
                }

                .securityShield {
                    background-color: #E2E1FF;
                }

                .findBtn {
                    color: #28d79c;
                    text-transform: inherit;
                    font-weight: 500;
                    font-size: 17px;
                    font-weight: 700;
                    margin-top: 15px;
                }
            }
        }


    }

    .info__container {
        padding-top: 3pc;
        padding-bottom: 3pc;
        background: #EBEFFA;
        margin-top: 30px;
        justify-content: center;
        align-items: center;

        @include respond-to(medium) {
            text-align: left;
        }


        .txt__grid {
            margin-bottom: 3pc;
            padding: 10px;

            .txt__header {
                color: #000000;
                font-weight: 700;
                font-size: 42px;
                margin-bottom: 1pc;
            }

            .txt__body {
                opacity: 0.7;
                color: #0D2637;
            }
        }
    }

    .info__container_2 {
        padding-top: 3pc;
        padding-bottom: 3pc;
        justify-content: center;
        align-items: center;
        flex-direction: column-reverse;

        @include respond-to(medium) {
            text-align: left;
            flex-direction: row;
        }

        @include respond-to(large) {}


        .txt__grid {
            margin-bottom: 3pc;
            padding: 10px;

            .txt__header {
                color: #000000;
                font-weight: 700;
                font-size: 42px;
                margin-bottom: 1pc;
            }

            .txt__body {
                opacity: 0.7;
                color: #0D2637;
            }
        }
    }

    .info__container_3 {
        padding-top: 3pc;
        padding-bottom: 3pc;
        background: #EBEFFA;
        margin-top: 30px;
        justify-content: center;
        align-items: center;

        @include respond-to(medium) {
            text-align: left;
        }


        .txt__grid {
            margin-bottom: 3pc;
            padding: 10px;

            .txt__header {
                color: #000000;
                font-weight: 700;
                font-size: 42px;
                margin-bottom: 1pc;
            }

            .txt__body {
                opacity: 0.7;
                color: #0D2637;
            }
        }
    }

    .vector {
        img {
            max-width: 100%;
            height: auto;
        }
    }


}