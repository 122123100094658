/* Loader */
.loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@keyframes typing-light {
  0%,
  100% {
    background-color: white;
    box-shadow: 12px 0px 0px 0px rgba(255, 255, 255, 0.2),
      24px 0px 0px 0px rgba(255, 255, 255, 0.2);
  }
  25% {
    background-color: rgba(255, 255, 255, 0.4);
    box-shadow: 12px 0px 0px 0px white,
      24px 0px 0px 0px rgba(255, 255, 255, 0.2);
  }
  75% {
    background-color: rgba(255, 255, 255, 0.4);
    box-shadow: 12px 0px 0px 0px rgba(255, 255, 255, 0.2),
      24px 0px 0px 0px white;
  }
}

@keyframes typing-dark {
  0%,
  100% {
    background-color: black;
    box-shadow: 12px 0px 0px 0px rgba(0, 0, 0, 0.2),
      24px 0px 0px 0px rgba(0, 0, 0, 0.2);
  }
  25% {
    background-color: rgba(0, 0, 0, 0.4);
    box-shadow: 12px 0px 0px 0px black, 24px 0px 0px 0px rgba(0, 0, 0, 0.2);
  }
  75% {
    background-color: rgba(0, 0, 0, 0.4);
    box-shadow: 12px 0px 0px 0px rgba(0, 0, 0, 0.2), 24px 0px 0px 0px black;
  }
}
.loader__icon {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  line-height: 1;
  position: relative;
  left: -12px;
}
.loader__icon--light {
  animation: typing-light 1s linear infinite alternate;
}
.loader__icon--dark {
  animation: typing-dark 1s linear infinite alternate;
}
.loader__text {
  margin-top: 10px;
  color: #626873;
  font-size: 14px;
}
