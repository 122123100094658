.logo__box {
    margin-left: 80px;
    align-items: center;

    .logo__text {
        text-decoration: none;
        font-size: 13px;
        font-weight: 600;
        opacity: 0.8;
        line-height: 16px;
        margin-left: 5px;
        text-align: left;
    }
}

.login__btn {
    text-transform: inherit;
    font-size: 16px;
    font-weight: 700;
}

.login__btn:hover {
    background: #28d79c !important;
}