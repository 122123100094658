@import '../../components/variables.scss';

.about__us {
    .container__grid {
        display: flex;
        flex-direction: column;
        margin-top: 50px;
        background: #EBEFFA;
        justify-content: center;
        align-items: center;

        .item__grid {
            text-align: center;

            .title__txt {
                font-weight: 700;
                margin-bottom: 30px;
                margin-top: 6pc;
            }

            .body__txt {
                font-weight: 400;
                font-size: 22px;
                opacity: 0.8;
                margin-bottom: 7pc;
                text-align: justify;

                @include respond-to(small) {
                    text-align: center;
                }

                @include respond-to(medium) {
                    text-align: center;
                }

                @include respond-to(large) {
                    text-align: center;
                }
            }
        }

    }

    .scrolling-wrapper {
        margin-top: -3rem;
        overflow-x: scroll;
        overflow-y: hidden;
        white-space: nowrap;
        padding-bottom: 30px;
        -webkit-overflow-scrolling: touch;

        &::-webkit-scrollbar {
            display: none;
        }

        .card {
            padding: 10px;
            border-radius: 24px;
            box-shadow: 0px 12px 16px rgba(16, 24, 40, 0.08), 0px 4px 6px rgba(16, 24, 40, 0.03);
            margin-right: 25px;
            margin-left: 25px;
            position: relative;
            display: inline-block;
            cursor: pointer;
            overflow: hidden;

            img {
                height: 100%;
            }
        }
    }

    .mission__grid {
        display: flex;
        flex-direction: column;
        min-height: 45vh;
        margin-top: 50px;
        justify-content: center;
        align-items: center;

        .title__txt {
            font-weight: 500;
            margin-bottom: 30px;
            opacity: 0.7;
            font-size: 18px;
        }

        .body__txt {
            font-weight: 600;
            font-size: 36px;
        }
    }

    .card__grid {
        justify-content: center;
        margin-top: 5pc;
        margin-bottom: 5pc;


        .item__box {
            padding: 30px;
            box-shadow: 0px 4px 114px rgba(0, 0, 0, 0.09);
            border-radius: 16px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding-left: 80px;

            @include respond-to(small) {
                padding-left: 30px;
            }

            .item {
                display: flex;
                flex-direction: row;

                .img__box {
                    padding: 15px;
                    border-radius: 15px;
                    margin-right: 20px;
                    background: $light;
                    .icon {
                        font-size: 40px;
                        color: $primary;
                    }
                }

                .item__txt__box {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: center;

                    .title {
                        font-weight: 700;
                    }

                    .caption__txt {
                        opacity: 0.8;
                        font-weight: 500;
                        color: #696871;
                    }
                }
            }

            .item__1 {
                padding: 15px 25px 15px 0px;

                @include respond-to(small) {
                    padding: 15px 25px 40px 0px;
                    border-right: 1px solid #dedede;
                    border-bottom: 1px solid #dedede;
                }

                @include respond-to(medium) {
                    border-right: 1px solid #dedede;
                    border-bottom: 1px solid #dedede;
                }

                @include respond-to(large) {}
            }

            .item__2 {
                padding: 15px 15px 15px 0px;

                @include respond-to(small) {
                    padding: 15px 15px 40px 60px;
                    border-bottom: 1px solid #dedede;
                }

                @include respond-to(medium) {
                    border-bottom: 1px solid #dedede;
                }

                @include respond-to(large) {
                }
            }

            .item__3 {
                padding: 15px 15px 15px 0px;
                @include respond-to(small) {
                    padding: 40px 15px 15px 0px;
                    border-right: 1px solid #dedede;
                }

                @include respond-to(medium) {
                    border-right: 1px solid #dedede;
                }

                @include respond-to(large) {
                }
            }

            .item__4 {
                padding: 15px 15px 15px 0px;
                @include respond-to(small) {
                    padding: 40px 15px 15px 60px;
                }

                @include respond-to(medium) {
                }

                @include respond-to(large) {
                }
            }


        }
    }


    .timeline__container {
        justify-content: flex-start;

        .timeline__dot {
            padding: 15px;
            border: 4px solid #28d79c;
            margin: 0;
        }

        .border__dotted {
            border: 4px dashed #28d79c;
        }

        .timeline__connecter {
            background: #28d79c;
            width: 3px;
            height: 70px;
        }

        .timeline__content {
            padding: 0px 0px 40px 25px;

            .timeline__year {
                font-weight: 800;
            }

            .timeline__body__txt {
                color: #0D2637;
                opacity: 0.7;
            }
        }

    }


    .btn__container {
        justify-content: center;
        margin-bottom: 10pc;

        .explore__btn {
            border-radius: 30px;
            padding: 10px 20px 10px 20px;
            text-transform: inherit;
            background: $primary;
            color: $dark;
            font-weight: 600;
            font-size: 16px;
        }

        .explore__btn:hover {
            background: #28d79c;
        }
    }


}