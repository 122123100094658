@import '../../components/variables.scss';

.payment__box {
    margin-top: 10pc;
    margin-bottom: 20pc;

    .paper {
        box-shadow: 0px 4px 40px rgb(0 0 0 / 8%);
        margin-bottom: 20pc;
    }
    .course__title{
        font-weight: 700;
    }

    .course__desc{
        opacity: 0.7;
    }

    .coupon__box {
        border: 1px solid #EFF4FD;
        border-radius: 4px;
        padding: 8px 10px 8px 0px;
        width: 100%;
        margin-right: 10px;
    }
    .input__box {
        border: 1px solid #EFF4FD;
        border-radius: 4px;
        padding: 8px 10px 8px 0px;
        width: 100%;
        margin-top: 10px;
    }
    
    .input__title {
        // font-weight: 900;
        font-size: 16px;
    }

    .coupon__btn {
        background: $danger;
        text-align: center;
        width: 90px;
        height: 49px;
        font-weight: 700;
    }

    .coupon__btn:hover {
        background: $danger;
    }

    .radio__color {
        &.Mui-checked {
          color: #28d79c;
        }
      }

      .pay__btn {
        width: 100%;
        padding-top: 10px;
        padding-bottom: 10px;
        background: $primary;
        font-size: 18px;
        color: $dark;
        font-weight: 600;
      }
      .pay__btn:hover {
        background: $primary;
      }

      .coupon__msg {
        color: $danger;
        margin-top: 10px;
      }

      .card__input {
        margin-top: 20px;
        margin-bottom: 30px;
        border: 1px solid #EFF4FD;
        border-radius: 4px;
        padding: 15px;
      }
}