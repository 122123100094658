@import '../../components/variables.scss';

.popularCourses {
  padding-top: 50px;

  .title {
    font-size: 40px;
    font-weight: 700;
    text-align: center;
  }

  .card-stack {
    display: flex;
    flex-wrap: wrap;
    gap: 50px;
    justify-content: center;
    padding: 0px;
    padding-bottom: 5pc;
    padding-top: 20px;

    .card {
      border-radius: 10px !important;
      box-shadow: 0px 4px 6px rgba(16, 24, 40, 0.03), 0px 12px 16px rgba(16, 24, 40, 0.08);

      // .chip {
      //   border-radius: 5px;
      // }

      // .chip1 {
      //   color: #28d79c;
      //   background-color: rgba(234, 55, 134, 0.1);
      // }

      // .chip2 {
      //   color: #7037EA;
      //   background-color: rgba(123, 55, 234, 0.1);
      // }

      .cardMedia {
        margin: 20px;
        border-radius: 10px;
      }

      .card__title {
        text-align: left;
        font-weight: 600;
        font-size: 20px;
      }

      .card__description {
        text-align: left;
        color: #0D2637;
        opacity: 0.7;
      }

      .btm__text {
        display: flex;
        align-content: space-between;
        align-items: flex-end;
        font-weight: 600;
      }

      .card__icon {
        color: $primary;
      }
    }

    @media screen and (max-width: 600px) {
      flex-direction: column;
      align-items: center;
    }
  }

  .more__courses__btn {
    background-color: #28d79c;
    color: #fff;
    border-radius: 30px;
    text-transform: inherit;
    font-weight: 600;
    padding: 10px 20px 10px 20px;
  }

  .more__courses__btn:hover {
    background-color: #28d79c;
    color: #fff;
  }
}