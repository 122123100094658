@import '../../components/variables.scss';


.courses__box {
  margin-bottom: 4pc;

  .courses__header {
    display: flex;
    flex-direction: row;
    background: #EBEFFA;
    padding-top: 7pc;
    padding-bottom: 2pc;
    padding-right: 20px;
    font-family: $font-family;

    .desc__box {
      text-align: left;
      padding: 50px 20px 30px 40px;

      @include respond-to(small) {
        padding: 50px 20px 30px 90px;
      }

      h1 {

        font-style: normal;
        font-weight: 800;
        font-size: 56px;
        line-height: 64px;
        color: #071E30;
      }

      p {
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 150%;
        opacity: 0.8;
        margin-top: 30px;
        color: #071E30;
      }

      .memberBtn {
        background-color: #28d79c;
        border-radius: 30px !important;
        font-size: 20px;
        line-height: 150%;
        padding: 16px 40px;
        text-transform: inherit;
      }
    }

    .img__box {
      padding-left: 2pc;

      @include respond-to(medium) {
        padding-top: 8pc;
      }

      @include respond-to(medium) {
        padding-top: 4pc;
      }

      // margin-right: 30px;
      img {
        max-width: 100%;
        height: auto;
        margin: 0;
      }
    }
  }

  .my__courses {
    padding-top: 50px;

    .title {
      font-size: 40px;
      font-weight: 700;
      text-align: center;
    }

    .card-stack {
      display: flex;
      flex-wrap: wrap;
      gap: 50px;
      justify-content: center;
      padding: 0px;
      padding-bottom: 5pc;
      padding-top: 20px;

      .card {
        border-radius: 10px !important;
        box-shadow: 0px 4px 6px rgba(16, 24, 40, 0.03), 0px 12px 16px rgba(16, 24, 40, 0.08);

        .chip {
          border-radius: 5px;
        }

        .chip1 {
          color: #28d79c;
          background-color: rgba(234, 55, 134, 0.1);
        }

        .chip2 {
          color: #7037EA;
          background-color: rgba(123, 55, 234, 0.1);
        }

        .cardMedia {
          margin: 20px;
          border-radius: 10px;
        }

        .card__title {
          text-align: left;
          font-weight: 600;
          font-size: 20px;
        }

        .card__description {
          text-align: left;
          color: #0D2637;
          opacity: 0.7;
        }

        .btm__text {
          display: flex;
          align-content: space-between;
          align-items: flex-end;
          font-weight: 600;
        }
      }

      @media screen and (max-width: 600px) {
        flex-direction: column;
        align-items: center;
      }
    }

    .goto__course__btn {
      color: #28d79c;
      border-radius: 30px;
      text-transform: inherit;
      font-weight: 600;
      margin-left: 20px;
      margin-bottom: 20px;
    }

    .goto__course__btn:hover {
      color: #28d79c;
    }
  }
}