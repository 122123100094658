
// Colors
$primary: #28d79c;
$primary-tint: #3edba6;
$primary-shade: #23bd89;
$light: #EBEFFA;
$dark: #071E30;
$dark-shade: #061a2a;
$dark-tint: #203545;
$danger: #eb445a;
$danger-tint: #ed576b;

// Font-family
$font-family: 'Nunito Sans', sans-serif;


// Breakpoints
$breakpoint-small: 600px;
$breakpoint-medium: 900px;
$breakpoint-large: 1200px;
$breakpoint-extra-large: 1536px;

@mixin respond-to($breakpoint) {
    @if $breakpoint == small {
      @media (min-width: $breakpoint-small) {
        @content;
      }
    }
  
    @if $breakpoint == medium {
      @media (min-width: $breakpoint-medium) {
        @content;
      }
    }
  
    @if $breakpoint == large {
      @media (min-width: $breakpoint-large) {
        @content;
      }
    }
  
    @if $breakpoint == extra-large {
      @media (min-width: $breakpoint-extra-large) {
        @content;
      }
    }
  }
  