@import '../../../components/variables.scss';

.footer__grid {
    background-color: #071E30;
    display: flex;
    width: 100%;
    padding-bottom: 4pc;
    padding-top: 8pc;
    justify-content: center;
    align-items: center;
    z-index: 1;
    color: #fff;
    text-align: center;
    align-items: center;
    padding-left: 6pc;
    padding-right: 6pc;


    .text__grid {
        display: flex;
        flex-direction: column;
        justify-content: center;
        opacity: 0.7;

        .footer__logo {
            display: flex;
            justify-content: center;

            .logoImage {
                margin-right: 10px;
            }

            .logoText {
                font-style: normal;
                font-weight: 400;
                font-size: 13px;
                line-height: 16px;
                text-decoration: none;
                color: #fff;
            }
        }
    }

    .description {
        margin-top: 25px !important;
    }


    .contact {
        font-weight: 700;
        margin-top: 5pc;
        opacity: 0.7;

        @include respond-to(small) {
            margin-top: 5pc;
        }

        @include respond-to(medium) {
            margin-top: 0px;
        }

    }

    .contact__info {
        line-height: 2;
        margin-top: 30px;
        opacity: 0.7;
    }

    .newsletter {
        font-weight: 700;
        margin-top: 5pc;
        opacity: 0.7;

        @include respond-to(small) {
            margin-top: 5pc;
        }

        @include respond-to(medium) {
            margin-top: 0px;
        }
    }

    .email__input {
        border-radius: 30px;
        border: 1px solid #353945;
        padding: 5px;
        margin-top: 20px;

        .arrow__icon {
            background-color: $primary-shade;
            padding: 20px 8px 20px 8px;
            border-radius: 30px;
            text-align: center;
            color: $dark-shade;
        }
    }

}

.rights__grid {
    text-align: center;

    @include respond-to(medium) {
        text-align: left;
    }

    .rights {
        margin-top: 5pc;
        color: #777E90;
        font-size: 12px;
    }
}