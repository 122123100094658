@import '../../components/variables.scss';

.contact__us {
    position: relative;

    .container__grid {
        position: relative;
        display: flex;
        margin-top: 11pc;
        margin-bottom: 15pc;
        background: #EBEFFA;
        align-items: center;
        justify-content: center;
        height: 75vh;

        @include respond-to(small) {
            flex-direction: row;
        }

        @include respond-to(medium) {
            justify-content: flex-start;
        }


        .info__grid {
            z-index: 0;
            align-items: center;
            flex-direction: column;

            @include respond-to(small) {}

            @include respond-to(medium) {
                align-items: center;
                padding-right: 20px;
                align-items: flex-end;
            }

            @include respond-to(large) {
                align-items: flex-end;
            }


            .grid__item {
                display: flex;
                flex-direction: column;
                align-items: start;
                padding: 40px 5px 15px 5px;

                .icon__box {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    margin-bottom: 10px;
                    color: #4F4F4F;

                    img {
                        margin-right: 8px;
                        width: 28px;
                    }

                    .contact__icon {
                        color: $primary;
                        font-size: 30px;
                        margin-right: 8px;
                    }
                }

                .txt__box {
                    color: #4F4F4F;
                    opacity: 0.8;
                    font-size: 20px;
                }
            }

        }

        .contact__now__grid {
            min-height: 90vh;
            background: #FFFFFF;
            box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.08);
            z-index: 1;
            padding: 50px 50px 90px 50px;
            text-align: center;
            margin-top: 30px;
            max-width: 70vh;

            @include respond-to(small) {}

            @include respond-to(medium) {
                position: absolute;
                right: 85px;
                border-radius: 24px;
                margin-top: 0px;
                text-align: left;
            }

            @include respond-to(large) {
                margin-left: 20px;
                right: 180px;
                max-width: 80vh;
            }

            .title__txt {
                font-weight: 700;
                margin-bottom: 20px;
            }

            .body__txt {
                color: #0D2637;
                opacity: 0.7;
            }

            .text__field__box {
                margin-top: 20px;

                .text__field {
                    width: 100%;
                    margin-top: 20px;
                    color: #828282;
                    opacity: 0.7;
                }

                .css-iw3zrc-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
                    color: #28d79c !important;
                }

                .css-1h8hdln-MuiInputBase-root-MuiInput-root:after {
                    border-color: #28d79c !important;
                }

            }

            .submit__btn {
                background: #28d79c;
                margin-top: 20px;
                border-radius: 30px;
                text-transform: inherit;
                padding: 8px 25px 8px 25px;
            }
        }
    }
}