@import '../../components/variables.scss';


.bar__content {
    display: flex;
    flex-direction: row;
    background: #071E30;
    padding-top: 7pc;
    padding-bottom: 10pc;
    padding-right: 20px;
    font-family: $font-family;

    .desc__box {
        text-align: left;
        padding: 50px 20px 30px 40px;

        @include respond-to(small) {
            padding: 80px 20px 30px 90px;
        }

        h1 {

            font-style: normal;
            font-weight: 800;
            font-size: 56px;
            line-height: 64px;
            color: #fff;
        }

        p {
            font-style: normal;
            font-weight: 400;
            font-size: 24px;
            line-height: 160%;
            color: #fff;
            opacity: 0.8;
            margin-top: 2pc;
            margin-bottom: 3pc;
        }

        .member__btn {
            background-color: $primary;
            color: $dark-shade;
            border-radius: 30px !important;
            font-size: 20px;
            font-weight: 600;
            line-height: 150%;
            padding: 16px 40px;
            text-transform: inherit;
        }
    }

    .img__box {
        padding-top: 3pc;
        padding-left: 2pc;

        // margin-right: 30px;
        .img {
            max-width: 100%;
            height: auto;
            margin: 0;
        }
    }
}

.info__box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    padding-top: 6pc;

    .title {
        font-size: 48px;
        margin-bottom: 2rem;
        font-weight: 900;
        margin-bottom: 10px;
    }

    .paragraph {
        margin-bottom: 2rem;
        margin-top: 2rem;
        text-align: center;
        margin: 0 !important;
        font-weight: 400;
        font-size: 24px;
        color: #0D2637;
        opacity: 0.7;
    }

    .cardContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 5rem;
        width: 100%;
        max-width: 100%;
    }

    .card {
        width: 90%;
        max-width: 300px;
        margin-right: 5rem;
        margin-bottom: 2rem;
        border-radius: 5px;
        border: 1px solid #E9E9E9;
        box-shadow: none;
        border-bottom-right-radius: 50px;

        .cardContent {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            .cardText {
                font-size: 18px;
                padding-left: 9px;
                font-weight: 700;
            }

            .cardIcon {
                padding: 10px;
                margin-left: 10px;
                margin-bottom: 20px;
                margin-top: 10px;
                border-bottom-right-radius: 15px;
                background: $light;
                .icon {
                    font-size: 34px;
                    color: $primary;
                }
            }

            .securityShield {
                background-color: #E2E1FF;
            }

            .findBtn {
                color: #28d79c;
                text-transform: inherit;
                font-weight: 500;
                font-size: 17px;
                font-weight: 700;
                margin-top: 15px;
            }
        }

    }


}

.main_grid {
    background-color: #071E30;
    padding-top: 30px;

    @include respond-to(small) {}

    @include respond-to(medium) {
        padding-left: 4pc;
    }

    @include respond-to(large) {
        border-top-left-radius: 75px;
        margin-left: 17pc !important;
        padding-left: 10pc;
    }

    margin-top: 6pc;
    display: flex;
    flex-direction: column !important;
    justify-content: center;
    position: relative;

    .textGrid {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: left;
        // padding: 50px;
        margin: 40px;

        @include respond-to(small) {
            margin: 4pc 5pc 5pc 5pc;
        }

        @include respond-to(medium) {
            margin: 4pc 12pc 5pc 12pc;
        }

        @include respond-to(large) {}


        p {
            text-align: left;
        }

        .search__btn {
            border-radius: 30px;
            padding: 2px 7px 2px 20px;
            text-transform: inherit;
            color: #FFF;
        }

        .find__more__btn {
            font-size: 13px;
            border-radius: 30px;
            padding: 2px 20px 2px 20px;
            text-transform: inherit;
        }
    }

    .img__grid {
        margin-top: 3pc;
        margin-right: 5pc;
        margin-left: 38px;

        @include respond-to(large) {
            margin-top: 0;
            margin-right: 13pc;
        }
    }

    .person__img {
        left: -130px;
        top: 70px;
        width: 350px;
        height: auto;
        max-width: 100%;
        border-radius: 15px;

        @include respond-to(small) {}

        @include respond-to(medium) {}

        @include respond-to(large) {
            position: absolute;
        }
    }

    .title {
        font-size: 36px;
        font-weight: bold;
        margin-bottom: 20px;
        text-align: left;
        padding: 0;
        margin: 0;
        font-style: normal;
        font-weight: 800;
        font-size: 48px;
        line-height: 45px;
    }

    .paragraph {
        font-size: 24px;
        margin-bottom: 5px;
        margin-top: 1pc;
        color: #fff;
        font-style: normal;
        font-weight: 400;
        font-size: 22px;
        opacity: 0.8;
        line-height: 30px;
        // padding: 5px 100px 10px 0px;
        padding: 5px 0px 10px 0px;
    }

    .listIcon {
        color: #28d79c;
        max-width: 42px !important;
    }

    .listItemText {
        color: #fff;
        font-weight: 800 !important;
    }

    // .search__icon {
    //     color: #fff !important;
    //     background-color: #28d79c;
    //     border-radius: 30px;
    //     padding-top: 10px;
    //     margin-left: 10px;
    // }
}


.bottom_box {
    display: flex;
    flex-direction: row-reverse;
    margin-top: 2pc;
    margin-bottom: 2pc;

    @include respond-to(small) {
        flex-direction: column-reverse;
    }

    @include respond-to(medium) {
        flex-direction: row-reverse;
    }

    @include respond-to(large) {
        flex-direction: row;
    }

    .text__grid {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 15px 30px 15px 30px;

        @include respond-to(small) {
            align-items: center;
        }

        @include respond-to(medium) {
            align-items: start;
            padding: 87px 10px 20px 130px;
        }

        @include respond-to(large) {
            align-items: start;
            padding: 87px 10px 20px 130px;
        }

        h1 {
            font-size: 40px;
            font-weight: 800;
        }

        p {
            font-size: 21px;
            line-height: 33px;
            text-align: left;
            opacity: 0.7;
            margin-top: 1pc;
        }
    }

    .logo__grid {
        text-align: center;
        padding-top: 2pc;
        display: flex;
        justify-content: center;

        @include respond-to(small) {
            padding-top: 60px;
        }

        @include respond-to(medium) {
            padding-top: 9pc;
        }

        @include respond-to(large) {
            padding-top: 7pc;
        }

        img {
            // max-width: 100%;
            height: auto;
        }
    }
}


.edu__model__grid {
    display: flex;
    margin-top: 7pc;
    padding-top: 40px;
    padding-bottom: 60px;
    align-items: center;
    background-color: #E5ECF7;
    flex-direction: column;

    @include respond-to(small) {
        flex-direction: column;
    }

    @include respond-to(medium) {
        flex-direction: column;
    }

    @include respond-to(large) {
        flex-direction: row;
        align-items: center;
    }

    .text__grid {
        font-size: 34px;
        text-align: center;
        font-weight: 700;

        h1{
            font-size: 40px;
            font-weight: 700;
        }

        @include respond-to(large) {
            padding-left: 10pc;
            text-align: left;
        }
    }

    .paper__container {
        display: flex;
        align-items: center;
        margin-top: 5rem;
        width: 100%;
        max-width: 100%;
        justify-content: center;

        @include respond-to(small) {
            justify-content: center;
        }

        @include respond-to(medium) {
            justify-content: center;
        }

        @include respond-to(large) {
            justify-content: left;
        }
    }

    .grid__paper {
        padding: 20px;
        margin: 20px;
        border-radius: 25px;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        max-width: 250px;
        min-height: 220px;

        img {
            text-align: left;
        }

        h3 {
            text-align: left;
        }

        p {
            text-align: left;
            opacity: 0.7;
        }
    }
}


.why__choose {
    background-color: #071E30;
    display: flex;
    margin-top: 6pc;
    align-items: center;
    padding: 50px 50px 0px 50px;

    @include respond-to(small) {
        flex-direction: column;
    }

    @include respond-to(medium) {
        flex-direction: column;
    }

    @include respond-to(large) {
        flex-direction: row;
    }

    .text__grid {
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;

        @include respond-to(small) {
            align-items: center;
        }

        @include respond-to(medium) {
            align-items: self-start;
            text-align: left;
            padding: 20px 0px 0px 70px;
        }

        @include respond-to(large) {
            align-items: self-start;
            text-align: left;
            padding-left: 6pc;
            padding-top: 40px;
            padding-bottom: 30px;
        }

        h1 {
            color: #FFF;
            font-size: 47px;
            margin-bottom: 1pc;
        }

        p {
            color: #FFF;
            opacity: 0.7;
            font-size: 24px;
        }

        .list__icon {
            color: #28d79c;
        }

        .list__item__txt {
            color: #FFF;
        }
    }

    .img__grid {
        margin-top: 50px;
        display: flex;
        justify-content: center;

        .phone__img {
            height: auto;
            max-width: 100%;
        }
    }
}