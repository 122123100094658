@import '../../components/variables.scss';

.member__grid {
  background-color: $dark-tint;
  padding-top: 1pc;
  padding-bottom: 4pc;
  z-index: 2;
  top: 7pc;
  position: relative;
  max-width: 100vh;
  margin: auto;
  margin-bottom: 35px;
  text-align: center;

  @include respond-to(small) {
    max-width: 90vh;
    border-radius: 20px;
  }

  @include respond-to(medium) {
    max-width: 100vh;
  }

  @include respond-to(large) {
    max-width: 140vh;
  }

  .text__grid {
    .description {
      color: #fff;
      font-size: 20px;
      font-weight: 700;
      padding: 20px 25px 30px 25px;

      @include respond-to(small) {
        font-size: 24px;
      }

      @include respond-to(medium) {
        font-size: 27px;
      }

      @include respond-to(large) {
        padding: 20px 10pc 30px 10pc;
      }
    }
  }

  .member__btn {
    background-color: $primary;
    border-radius: 30px;
    text-transform: inherit;
    font-weight: 600;
    font-size: 16px;
    color: $dark-shade;
    padding: 10px 25px 10px 25px;
  }

  .member__btn:hover {
    background-color: $primary-tint;
  }
}